import { Container } from '@/components/container'
import { styles } from '@/styles/styles'
import { css, useTheme } from '@emotion/react'
import React, { HTMLAttributes } from 'react'

// @ts-ignore
import decoration from '@/images/decoration-01.svg'
// @ts-ignore
import background from '@/images/background-01.svg'
// @ts-ignore
import banner1 from '@/images/banner-01.png'
// @ts-ignore
import banner2 from '@/images/banner-02.png'
// @ts-ignore
import logo from '@/images/logo-02.svg'
import { InViewFadeIn } from './fade-in'

export interface FooterProps extends HTMLAttributes<HTMLElement> {}

export function Footer(props: FooterProps) {
  return (
    <footer {...props}>
      <BannerSection />
      <FooterSection />
    </footer>
  )
}

function BannerSection() {
  const theme = useTheme()
  return (
    <InViewFadeIn>
      <div
        css={[
          css`
            /* width: 100%; */
            height: 1.5rem;
            background: url(${decoration}) center / 192rem;
          `,
        ]}
      />
      <div
        css={css`
          padding: 8rem 6rem;
          background-color: ${theme.colors.primary.main};
        `}
      >
        <Container css={styles.textCenter}>
          <div>
            <a
              href="https://admissions.g30.nagoya-u.ac.jp/jp/"
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                transition: filter 0.3s;
                &:hover {
                  filter: brightness(0.9);
                }
              `}
            >
              <img
                src={banner1}
                alt="名古屋大学 G30国際プログラム"
                css={css`
                  width: 44.2rem;
                `}
              />
            </a>

            <p
              css={css`
                margin-top: 1rem;
                font-size: ${theme.fontSizes.body2};
                color: ${theme.colors.white};
              `}
            >
              G30 INTERNATIONAL PROGRAM{' '}
              <span css={styles.inlineBlock}>G30国際プログラム</span>
            </p>
          </div>

          <div
            css={css`
              margin-top: 4rem;
            `}
          >
            <a
              href="https://www.mmfe.or.jp/"
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                transition: filter 0.3s;
                &:hover {
                  filter: brightness(0.9);
                }
              `}
            >
              <img
                src={banner2}
                alt="一般財団法人 三菱みらい育成財団"
                css={css`
                  width: 44.2rem;
                `}
              />
            </a>

            <p
              css={css`
                margin-top: 1rem;
                font-size: ${theme.fontSizes.body2};
                color: ${theme.colors.white};
              `}
            >
              このプロジェクトは
              <span css={styles.inlineBlock}>
                <span css={styles.inlineBlock}>
                  一般財団法人 三菱みらい育成財団の
                </span>
                <span css={styles.inlineBlock}>助成により実施します。</span>
              </span>
            </p>
          </div>
        </Container>
      </div>
    </InViewFadeIn>
  )
}

function FooterSection() {
  const theme = useTheme()
  return (
    <InViewFadeIn
      css={theme => css`
        padding: 7rem 6rem 6rem;
        background: ${theme.colors.gray.lighter} url(${background}) center /
          192rem;
      `}
    >
      <Container css={styles.textCenter}>
        <div
          css={css`
            padding-bottom: 7rem;
            border-bottom: solid 1px;
          `}
        >
          <p>
            東海国立大学機構 <span css={styles.inlineBlock}>名古屋大学</span>{' '}
            <span css={styles.inlineBlock}>
              名大みらい育成プロジェクト事務局
            </span>
          </p>

          <p
            css={css`
              margin-top: 2rem;
              font-size: ${theme.fontSizes.body2};
              line-height: ${theme.lineHeight.tight};
            `}
          >
            TEL：052-789-3530
            <br />
            E-mail：nu-mirai@bio.nagoya-u.ac.jp
          </p>
        </div>

        <div
          css={css`
            padding-top: 5rem;
          `}
        >
          <a
            href="https://www.nagoya-u.ac.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              alt="名古屋大学ロゴ"
              width={240}
              height={56}
              css={css`
                width: 24rem;
                height: 5.6rem;
              `}
            />
          </a>

          <p
            css={css`
              margin-top: 2rem;
              font-size: ${theme.fontSizes.body4};
              line-height: ${theme.lineHeight.tight};
            `}
          >
            Copyright © 2022 Nagoya University{' '}
            <span css={styles.inlineBlock}>ALL Rights Reserved.</span>
          </p>
        </div>
      </Container>
    </InViewFadeIn>
  )
}
