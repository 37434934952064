export const theme = {
  breakpoints: {
    sm: '(min-width: 360px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1000px)',
  },
  colors: {
    primary: {
      light: '#618899',
      main: '#4CB0CE',
      dark: '#0076B9',
    },
    blue: {
      main: '#0076B9',
      dark: '',
    },
    magenta: {
      main: '#DB087A',
      dark: '',
    },
    yellow: {
      light: '#FFF790',
      main: '#F8BB00',
      dark: '',
    },
    orange: {
      main: '#F3A000',
      dark: '#EB6A02',
    },
    green: {
      main: '#58B231',
      dark: '#00953B',
    },
    red: {
      main: '#D02C2C',
      dark: '',
    },
    gray: {
      darker: '#2B2A2A',
      dark: '#4E4E4E',
      main: '#707070',
      light: '#C1C2C4',
      lighter: '#F5F5F5',
    },
    white: '#FFF',
    black: '#4D5055',
  },
  fonts: {
    default:
      "'YuGothic','Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', 'Meiryo','ＭＳ ゴシック',sans-serif",
    roboto: "'Roboto', sans-serif",
  },
  fontSizes: {
    heading0: '6rem',
    heading1: '5rem',
    heading2: '4rem',
    heading3: '3rem',
    body0: '2.5rem',
    body1: '2.2rem',
    body2: '2rem',
    body3: '1.8rem',
    body4: '1.6rem',
    body5: '1.4rem',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  letterSpacings: {
    ultratight: '0.02em',
    tighter: '0.06em',
    tight: '0.08em',
    normal: '0.1em',
    relaxed: '0.12em',
    wide: '0.15em',
  },
  lineHeight: {
    none: '1',
    tighter: '1.1',
    tight: '1.3',
    normal: '1.5',
    relaxed: '1.75',
    loose: '2',
    looser: '2.5',
  },
}

export type Theme = typeof theme
