import { WrapRoot } from './src/components/wrap-root'

export const wrapRootElement = WrapRoot

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    setTimeout(() => {
      document.querySelector(`${location.hash}`).scrollIntoView()
    }, 0)
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}
