import { useCallback, useState } from 'react'

export interface UseDisclosureProps {
  defaultIsOpen?: boolean
}

export function useDisclosure(props: UseDisclosureProps = {}) {
  const { defaultIsOpen = false } = props

  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onToggle = useCallback(() => {
    const action = isOpen ? onClose : onOpen
    action()
  }, [isOpen, onOpen, onClose])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>
