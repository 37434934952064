import { css } from '@emotion/react'
import useWebAnimations from '@wellyshen/use-web-animations'
import React, { HTMLAttributes, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export interface FadeInProps extends HTMLAttributes<HTMLDivElement> {
  isShow: boolean
}

const animationOptions = {
  duration: 800,
  easing: 'ease-out',
  fill: 'both',
} as const
export function FadeIn({ children, isShow, ...props }: FadeInProps) {
  const { ref: aniRef, getAnimation } = useWebAnimations<HTMLDivElement>({
    autoPlay: false,
    keyframes: [
      {
        opacity: 0,
      },
      { opacity: 1 },
    ],
    animationOptions,
  })

  useEffect(() => {
    if (isShow) {
      const animation = getAnimation()
      animation?.play()
    }
  }, [isShow])

  return (
    <div
      css={css`
        opacity: 0;
      `}
      ref={aniRef}
      {...props}
    >
      {children}
    </div>
  )
}

export interface InViewFadeInUpProps extends HTMLAttributes<HTMLDivElement> {
  withUpMotion?: boolean
}

export function InViewFadeIn({
  children,
  withUpMotion = true,
  ...props
}: InViewFadeInUpProps) {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 })

  return (
    <div ref={ref} {...props}>
      <FadeIn
        isShow={inView}
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
        `}
      >
        {children}
      </FadeIn>
    </div>
  )
}

export function FadeInUp({ children, isShow, ...props }: FadeInProps) {
  const { ref: aniRef, getAnimation } = useWebAnimations<HTMLDivElement>({
    autoPlay: false,
    keyframes: [
      {
        opacity: 0,
        transform: 'translateY(2rem)',
      },
      { opacity: 1, transform: 'translateY(0%)' },
    ],
    animationOptions,
  })

  useEffect(() => {
    if (isShow) {
      const animation = getAnimation()
      animation?.play()
    }
  }, [isShow])

  return (
    <div
      css={css`
        opacity: 0;
      `}
      ref={aniRef}
      {...props}
    >
      {children}
    </div>
  )
}

export interface InViewFadeInUpProps extends HTMLAttributes<HTMLDivElement> {
  withUpMotion?: boolean
}

export function InViewFadeInUp({
  children,
  withUpMotion = true,
  ...props
}: InViewFadeInUpProps) {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 })

  return (
    <div ref={ref} {...props}>
      <FadeInUp
        isShow={inView}
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
        `}
      >
        {children}
      </FadeInUp>
    </div>
  )
}
