import React, { HTMLAttributes, useEffect, useState } from 'react'
import useMedia from 'use-media'
import type { WindowLocation } from '@reach/router'
import { css, useTheme } from '@emotion/react'
import { Link } from 'gatsby'
import { DrawerNavi, Navi } from './navi'
import { useScrollPosition } from '@/hooks/use-scroll-position'
import { InViewFadeIn } from './fade-in'

// @ts-ignore
import logo from '@/images/logo-01.svg'
// @ts-ignore
import decoration from '@/images/decoration-01.svg'
// @ts-ignore
import decorationSp from '@/images/decoration-01-sp.svg'

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
  location: WindowLocation
}

export function Header({ location, ...props }: HeaderProps) {
  const [isTopPage, setIsTopPage] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const scrollPos = useScrollPosition()
  const theme = useTheme()
  const geMd = useMedia(theme.breakpoints.md)

  useEffect(() => {
    if (scrollPos > 100) {
      setIsScrolled(true)
    } else if (scrollPos < 50) {
      setIsScrolled(false)
    }
  }, [scrollPos, setIsScrolled])

  useEffect(() => {
    setIsTopPage(location.pathname === '/')
  }, [location.pathname])

  return (
    <header
      css={css`
        top: 0;
        left: 0;
        z-index: 10;
        width: 100vw;
        background-color: ${theme.colors.white};
        font-weight: ${theme.fontWeights.bold};

        @media ${theme.breakpoints.md} {
          position: relative;
          height: 18rem;
        }
      `}
      style={{
        ...(isTopPage
          ? geMd
            ? {
                position: 'absolute',
                height: '26rem',
                backgroundColor: 'transparent',
              }
            : {
                position: 'fixed',
                height: '14rem',
                backgroundColor: isScrolled
                  ? theme.colors.white
                  : 'transparent',
                transition: '0.4s ease-out',
                transitionProperty: 'background-color, height',
              }
          : geMd
          ? {
              position: 'relative',
              height: '18rem',
            }
          : {
              position: 'sticky',
              height: '14rem',
            }),
      }}
      {...props}
    >
      <InViewFadeIn
        css={css`
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            height: 100%;
            padding: 2rem 2.6rem 0;

            @media ${theme.breakpoints.md} {
              padding: 2.5rem 5rem 0;
            }
          `}
          style={
            isTopPage && geMd
              ? {
                  paddingTop: '3.5rem',
                }
              : {}
          }
        >
          <Link to="/">
            <img
              src={logo}
              alt="nu Mirai Education Project"
              css={css`
                width: 10rem;
                height: 10rem;
                transition: 0.5s;
                transition-property: width, height;

                @media ${theme.breakpoints.md} {
                  transition: unset;
                }
              `}
              style={
                isTopPage
                  ? geMd
                    ? {
                        width: '23rem',
                        height: '23rem',
                      }
                    : !isScrolled
                    ? {
                        width: '19rem',
                        height: '19rem',
                      }
                    : {}
                  : geMd
                  ? {
                      width: '13rem',
                      height: '13rem',
                    }
                  : {}
              }
            />
          </Link>

          {geMd ? (
            <Navi
              isTopPage={isTopPage}
              isScrolled={isScrolled}
              style={
                isTopPage
                  ? {
                      color: theme.colors.white,
                    }
                  : {}
              }
            />
          ) : (
            <DrawerNavi isTopPage={isTopPage} isScrolled={isScrolled} />
          )}
        </div>

        <div
          css={css`
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.6rem;
            background: url(${decorationSp}) center / 100%;
            opacity: ${!isTopPage || isScrolled ? 1 : 0};
            transition: opacity 0.4s;

            @media ${theme.breakpoints.md} {
              height: 1rem;
              background-image: url(${decoration});
              opacity: 1;
              transition: unset;
            }
          `}
          data-is-toppage={isTopPage}
          data-is-desktop={geMd}
          style={
            (isTopPage && geMd) || (isTopPage && !isScrolled)
              ? {
                  opacity: 0,
                }
              : {
                  opacity: 1,
                }
          }
        />
      </InViewFadeIn>
    </header>
  )
}
