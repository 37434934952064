import { css, Interpolation } from '@emotion/react'
import { Theme } from './theme'

export const globalStyles: Interpolation<Theme> = theme => css`
  :root {
    --wp-color--black: #000;
    --wp-color--white: #fff;
    --wp-color--cyan-bluish-gray: #abb8c3;
    --wp-color--pale-pink: #f78da7;
    --wp-color--vivid-red: #cf2e2e;
    --wp-color--luminous-vivid-orange: #ff6900;
    --wp-color--luminous-vivid-amber: #fcb900;
    --wp-color--light-green-cyan: #7bdcb5;
    --wp-color--vivid-green-cyan: #00d084;
    --wp-color--pale-cyan-blue: #8ed1fc;
    --wp-color--vivid-cyan-blue: #0693e3;
    --wp-color--vivid-purple: #9b51e0;
  }

  html {
    font-size: 5px;

    @media ${theme.breakpoints.sm} {
      font-size: 6px;
    }

    @media ${theme.breakpoints.md} {
      font-size: 8px;
    }
  }

  body {
    font-family: ${theme.fonts.default};
    font-size: ${theme.fontSizes.body1};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacings.normal};
    line-height: ${theme.lineHeight.normal};
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    font: inherit;
    color: inherit;
    background: unset;
    border: unset;
    padding: unset;
    outline: 0;
  }

  a,
  button {
    cursor: pointer;
  }

  input,
  select,
  textarea {
    border: unset;
    font-size: inherit;
    box-sizing: border-box;
  }

  select {
    appearance: none;
  }
  select::-ms-expand {
    display: none;
  }

  .has-text-align-center {
    text-align: center;
  }

  .has-text-align-right {
    text-align: right;
  }

  .has-cyan-bluish-gray-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--cyan-bluish-gray);
    }
  }
  .has-cyan-bluish-gray-background-color {
    &.has-background {
      background-color: var(--wp-color--cyan-bluish-gray);
    }
  }

  .has-pale-pink-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--pale-pink);
    }
  }
  .has-pale-pink-background-color {
    &.has-background {
      background-color: var(--wp-color--pale-pink);
    }
  }

  .has-vivid-red-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--vivid-red);
    }
  }
  .has-vivid-red-background-color {
    &.has-background {
      background-color: var(--wp-color--vivid-red);
    }
  }

  .has-luminous-vivid-orange-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--luminous-vivid-orange);
    }
  }
  .has-luminous-vivid-orange-background-color {
    &.has-background {
      background-color: var(--wp-color--luminous-vivid-orange);
    }
  }

  .has-luminous-vivid-amber-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--luminous-vivid-amber);
    }
  }
  .has-luminous-vivid-amber-background-color {
    &.has-background {
      background-color: var(--wp-color--luminous-vivid-amber);
    }
  }

  .has-light-green-cyan-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--light-green-cyan);
    }
  }
  .has-light-green-cyan-background-color {
    &.has-background {
      background-color: var(--wp-color--light-green-cyan);
    }
  }

  .has-vivid-green-cyan-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--vivid-green-cyan);
    }
  }
  .has-vivid-green-cyan-background-color {
    &.has-background {
      background-color: var(--wp-color--vivid-green-cyan);
    }
  }

  .has-pale-cyan-blue-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--pale-cyan-blue);
    }
  }
  .has-pale-cyan-blue-background-color {
    &.has-background {
      background-color: var(--wp-color--pale-cyan-blue);
    }
  }

  .has-vivid-cyan-blue-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--vivid-cyan-blue);
    }
  }
  .has-vivid-cyan-blue-background-color {
    &.has-background {
      background-color: var(--wp-color--vivid-cyan-blue);
    }
  }

  .has-vivid-purple-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--vivid-purple);
    }
  }
  .has-vivid-purple-background-color {
    &.has-background {
      background-color: var(--wp-color--vivid-purple);
    }
  }

  .has-black-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--black);
    }
  }
  .has-black-background-color {
    &.has-background {
      background-color: var(--wp-color--black);
    }
  }

  .has-white-color {
    &.has-text-color,
    &.has-inline-color {
      color: var(--wp-color--white);
    }
  }
  .has-white-background-color {
    &.has-background {
      background-color: var(--wp-color--white);
    }
  }
`
