module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://miraiikusei.bio.nagoya-u.ac.jp/wp/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo-01.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4fae4fd9d10617c3bab82ec38e00cab7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/hirotakachihara/Documents/workspace/projects/tmc/nu-mirai/gatsby/node_modules/gatsby-remark-images","id":"16fc2a25-6eb1-59a0-84e3-29691ba56c37","name":"gatsby-remark-images","version":"6.9.1","modulePath":"/Users/hirotakachihara/Documents/workspace/projects/tmc/nu-mirai/gatsby/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1000},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/hirotakachihara/Documents/workspace/projects/tmc/nu-mirai/gatsby","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
