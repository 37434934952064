import { css } from '@emotion/react'
import useWebAnimations from '@wellyshen/use-web-animations'
import React, { HTMLAttributes, useEffect } from 'react'
import useResizeObserver from 'use-resize-observer'

export interface CollapseProps extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean
  defaultOpen?: boolean
}

const animationOptions = {
  duration: 200,
  fill: 'both',
} as const

export function Collapse({
  children,
  isOpen,
  defaultOpen,
  ...props
}: CollapseProps) {
  const { ref: resizeRef, height = 0 } = useResizeObserver<HTMLDivElement>()
  const { ref: animateRef, animate } = useWebAnimations<HTMLDivElement>()

  useEffect(() => {
    if (isOpen) {
      animate({
        keyframes: [{ height: 0 }, { height: `${height}px` }],
        animationOptions,
      })
    } else {
      animate({
        keyframes: [{ height: `${height}px` }, { height: 0 }],
        animationOptions,
      })
    }
  }, [isOpen, animate])

  return (
    <div
      style={{ height: defaultOpen ? 'auto' : 0 }}
      css={css`
        overflow: hidden;
      `}
      ref={animateRef}
      {...props}
    >
      <div ref={resizeRef}>{children}</div>
    </div>
  )
}
