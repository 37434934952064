import { css, Global } from '@emotion/react'
import resetCSS from 'emotion-reset'
import React, { HTMLAttributes, ReactNode } from 'react'
import type { WindowLocation } from '@reach/router'
import { globalStyles } from '@/styles/global'
import { Footer } from '@/components/footer'
import { SEO } from './seo'
import { Header } from './header'

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  location: WindowLocation
}

export function Layout({ children, location, ...other }: LayoutProps) {
  return (
    <div
      id="page-top"
      css={css`
        position: relative;
      `}
      {...other}
    >
      <Global styles={[resetCSS, globalStyles]} />
      <SEO />

      <Header location={location} />

      <main
        css={css`
          position: relative;
          overflow: hidden;
        `}
      >
        {children}
      </main>

      <Footer />
    </div>
  )
}
