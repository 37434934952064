exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-messages-tsx": () => import("./../../../src/pages/messages.tsx" /* webpackChunkName: "component---src-pages-messages-tsx" */),
  "component---src-pages-news-list-tsx": () => import("./../../../src/pages/news-list.tsx" /* webpackChunkName: "component---src-pages-news-list-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-introduction-tsx": () => import("./../../../src/templates/introduction.tsx" /* webpackChunkName: "component---src-templates-introduction-tsx" */)
}

