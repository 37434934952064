import { css } from '@emotion/react'

export const styles = {
  relative: css`
    position: relative;
  `,
  absolute: css`
    position: absolute;
  `,
  fullSize: css`
    width: 100vw;
    height: 100vh;
  `,
  fullWidth: css`
    width: 100vw;
  `,
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  inlineBlock: css`
    display: inline-block;
  `,
  textCenter: css`
    text-align: center;
  `,
  textRight: css`
    text-align: right;
  `,
  textNoWrap: css`
    white-space: nowrap;
  `,
}
