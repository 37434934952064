import { ThemeProvider } from '@emotion/react'
import React, { ReactNode } from 'react'
import { theme } from '@/styles/theme'
import { RecoilRoot } from 'recoil'
import { Autoplay, Swiper as RealSwiper } from 'swiper'
import { Location } from '@reach/router'
import { Layout } from './layout'

import 'swiper/css'
import '@/styles/icomoon.css'

export interface WrapRootProps {
  element: ReactNode
}

RealSwiper.use([Autoplay])

export function WrapRoot({ element }: WrapRootProps) {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Location>
          {({ location }) => <Layout location={location}>{element}</Layout>}
        </Location>
      </ThemeProvider>
    </RecoilRoot>
  )
}
