import { css, useTheme } from '@emotion/react'
import React, { HTMLAttributes } from 'react'

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {}

export function Container(props: ContainerProps) {
  return (
    <div
      css={css`
        width: 100%;
        max-width: 100rem;
        margin: 0 auto;
      `}
      {...props}
    />
  )
}
